import { Navigate } from "react-router-dom";
import { purchaseProduct } from "../../../apiCalls/urls/urlFunction";
import { actionNotifier } from "../../../components/tostify";

export async function productPurchaseApi(payload) {
    const purchaseResponse = await purchaseProduct(payload);

    if (purchaseResponse?.data?.status) {
        // When (gateway = true  and payuSdk = false) =>  redirect to orders page
        /****
         *  If (Api Status === True && Gateway === True) {
         *      redirect to payU
         *  }else if(Api Status === True && Gateway === false){
         *      redirect to Order Page
         *  }
         */

        if (purchaseResponse) {
            const payU = purchaseResponse?.data?.data?.gateway;
            const wallet = purchaseResponse?.data?.data?.walletPaid;


            if (payU === true && wallet === 0) {
                // redirect to -> payU
                //User's Pay complete Payment Online
                startPayU(purchaseResponse?.data, payload);
            } else if (payU === true && wallet !== 0) {
                // redirect to -> payU
                //User's Pay partially Payment Online and partially Payment from Wallet
                startPayU(purchaseResponse?.data, payload);
            } else if (payU === false && wallet !== 0) {
                // redirect to -> Order's Page
                //User's Pay complete Payment Wallet
                return ('ORDER_PURCHASE_REDIRECT_TO_ORDERS');
            } else {
                // redirect to -> payU 
                startPayU(purchaseResponse?.data, payload);
            }

            // if (payU || (payU && wallet === 0)) {
            //     // Redirected to pay u payment gateway
            //     // Here We save user Purchase Request Now call Pay-u Api
            //     startPayU(purchaseResponse?.data, payload);
            // } else if (payU === false && wallet >= 0) {
            //     // Redirected to orders page
            //     navigate('/profile/order');
            // }
        }
    } else {
        actionNotifier.error(purchaseResponse?.message, { position: "bottom-right", autoClose: 5000 })
    }
}

async function startPayU(purchasedItem, payload) {
    try {
        const totalAmount = purchasedItem.totalAmount;
        const name = payload.customerName;
        const email = payload.emailID;
        const mobile = payload.mobileNo;
        const denominations = payload.denominations.map((item) => {
            return {
                ...item,
                amount: parseInt(Math.round(item.amount - (item.amount / 100 * item.discount))),
                total: parseInt(Math.round(item.amount - (item.amount / 100 * item.discount)))
            };
        });


        // It first checks if the required fields (totalAmount, name, mobile, email) are filled. If not, it throws an error.
        if (totalAmount === 0) throw new Error('Please Select Denomination!');
        if (!name) throw new Error('Please Enter Receiver Name!');
        if (!mobile) throw new Error('Please Enter Receiver Mobile!');
        if (!email) throw new Error('Please Enter Receiver Email!');

        // It then sets loading.value to true, indicating that the process has started.


        // A payObject is created
        const payObject = {
            productId: payload.productId,
            walletPayment: payload.walletPayment,
            emailID: email,
            mobileNo: mobile,
            customerName: name,
            newSite: payload.newSite,
            denominations: [...denominations],
            device_type: 0,
        };

        // It then makes an asynchronous API call to placeOrder with payObject as the argument
        const { status, data, message } = await paymentGateway(payObject, purchasedItem);

        if (!status) throw new Error(message);
        if (data.gateway) {
            // paymentGateway(data)
        } else {
            // $store.dispatch('notify/success', message);
            // $router.push({name: 'reports'})
        }
        // loading.value = true;

    } catch (error) {

    }
}

async function paymentGateway(data, purchase) {
    try {
        const payUObject = { ...data, ...purchase.data.product_info };
        const form = document.createElement("form");
        form.setAttribute('method', 'post');
        form.setAttribute('action', 'https://secure.payu.in/_payment');
        form.setAttribute('service_provider', 'payu_paisa');
        form.setAttribute('target', 'payumoney');
        form.setAttribute('target', '_self');

        for (const prop in payUObject) {
            if (payUObject.hasOwnProperty(prop)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = prop;
                input.value = payUObject[prop];
                form.appendChild(input);
            }
        }

        document.body.appendChild(form);
        form.submit();
    } catch (error) {

    }
}