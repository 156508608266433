import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import LatestBlog from "./LatestBlog";
import Layout from "../../layouts";
import AllBlogs from "./AllBlogs";
import { Breadcrumbs, Container, Box } from "@mui/material";
import { getBlogs } from "../../apiCalls/urls/urlFunction";
import PageBreadcrumb from "../../components/other/PageBreadcrumb";
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
const seoData = {
  title: '99Gift Blog | Expert Tips & Trends in Gift Cards & Vouchers',
  description: 'Stay updated with the latest trends and expert tips on gift cards at the 99Gift Blog. Discover unique ideas, industry insights, and how to make the most of your gifting experience',
}

const Blogpage = (props) => {
  const { isMobile = false } = props;
  const [blogData, setBlogData] = useState();
  const [latestBlogData, setLatestBlogData] = useState();
  const searchItem = useLocation();
  const canonicalUrl = `https://www.99gift.in${searchItem.pathname}`;
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Blogs Reagarding Gift Cards - 99gift</title>
        <meta name="description" content="Blogs Reagarding Gift Cards - 99gift" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content="Blogs Reagarding Gift Cards - 99gift" />
        <meta property="og:description" content="Blogs Reagarding Gift Cards - 99gift" />
        <link rel="canonical" href={canonicalUrl} /> {/* Dynamic Canonical URL */}
      </Helmet>
      <Container maxWidth="xl" sx={{ backgroundColor: "white.main", }}>

        {!isMobile &&
          (
            <PageBreadcrumb
              home="Home"
              currentProduct="Blogs"
            />
          )
        }
      </Container>
      <Container maxWidth="xl" sx={{ backgroundColor: "#fff", mt: { xs: 2, sm: 0 } }} disableGutters>
        {/* {breadcrumbsSection(handleClick)} */}


        <LatestBlog blogData={latestBlogData} />
        <AllBlogs blogData={blogData} />
      </Container>
    </React.Fragment>
  );

  async function fetchBlogs() {
    try {
      const blogResponse = await getBlogs();

      const blogStatus = blogResponse?.data?.status;
      // const blogMessage = blogResponse?.data?.message;

      if (!blogResponse || !blogResponse?.status) {
        throw new Error(blogResponse ? blogResponse.message : "Unknown");
      }

      if (blogStatus) {
        const mainData = blogResponse?.data?.data;
        setLatestBlogData(mainData?.[mainData.length - 1]);
        const newData = mainData.slice(0, mainData?.length - 1);
        setBlogData(newData);
      }
    } catch (error) {
      if (error.message === "Invalid Password!") {
        alert("Wrong Password !");
      } else if (error.message === "Mobile Does not exist! Register Now!") {
        alert("User is Not Exist!");
      } else {
        alert("An error occurred while login. Please try again later.");
      }
    }
  }
};

Blogpage.propTypes = {};
export default Blogpage;
function breadcrumbsSection(handleClick) {
  return (
    <Box
      onClick={handleClick}
      sx={{
        padding: "5px 15px",
        minHeight: "50px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#F1F5F6",
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ backgroundColor: "backgroundPrimary.main" }}
      >
        <NavLink underline="hover" href="/" sx={{ color: "#fff" }}>
          Home
        </NavLink>
        <NavLink
          underline="hover"
          color="text.primary"
          href="/blogs"
          aria-current="page"
        >
          Blogs
        </NavLink>
      </Breadcrumbs>
    </Box>
  );
}
