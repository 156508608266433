import React from "react";
import PropTypes from "prop-types";
import { Grid, Chip, Button, Typography, Box } from "@mui/material";
import DefaultBlogImage from "../../assets/others/blog_image_default1.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { titleToUrl } from "../../shared/extra";
import { NavLink } from "react-router-dom";
const LatestBlog = ({ blogData }) => {

  return (
    <Grid
      container
      maxWidth="false"
      sx={{
        p: { xs: 1, md: 2 },
        backgroundColor: "backgroundPrimary.main",
      }}
    >
      <Grid item xs={12} md={5}>
        {/* Blog image */}
        <Box
          component="img"
          src={DefaultBlogImage}
          alt="Blog Image"
          loading="lazy"
          style={{ width: "100%", height: "auto", objectFit: "cover" }}
        />
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={7}
        sx={{
          px: { xs: 0, md: 4 },
          my: { xs: 3, md: 0 },
        }}
      >
        {/* Blog details */}
        <Grid item xs={12}>
          <Chip
            label="New"
            sx={{
              backgroundColor: "primary.main",
              color: "white.main",
              fontSize: { xs: "12px", md: "12px" },
              px: { xs: 1, md: 1 },
              borderRadius: { xs: 1, md: 1 },
              mx: { xs: "3px", md: "3px" },
            }}
          />
          <Chip
            label="20 March 2024"
            variant="outlined"
            icon={<CalendarMonthIcon />}
            sx={{
              color: "primary.main",
              fontSize: { xs: "12px", md: "12px" },
              px: { xs: 1, md: 2 },
              borderRadius: { xs: 1, md: 1 },
              mx: { xs: "3px", md: "3px" },
            }}
          />

          <Chip
            label="3 Min."
            variant="outlined"
            icon={<ScheduleIcon />}
            sx={{
              color: "primary.main",
              fontSize: { xs: "12px", md: "12px" },
              px: { xs: 1, md: 2 },
              borderRadius: { xs: 1, md: 1 },
              mx: { xs: "3px", md: "3px" },
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ my: { xs: 1, md: 0 } }}>
          <NavLink to={`/blog-details/${titleToUrl(blogData?.title)}`} style={{ textDecoration: "none" }}>
            <Typography
              variant="h1"
              color="primary.main"
              sx={{
                fontSize: { xs: "22px", md: "28px" },
                fontWeight: 500,
              }}
            >
              {blogData?.title ? blogData.title : null}
            </Typography>
          </NavLink>
          <Typography
            variant="subtitle1"
            color="textPrimary.main"
            sx={{
              fontSize: { xs: "16px", md: "17px" },
              my: { xs: 1, md: 1 },
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: { xs: 7, md: 8 }, // Limit to 4 lines on mobile and 6 lines on md devices
              maxHeight: { xs: "200px", md: "235px" }, // Approximately 4 lines on mobile and 6 lines on md devices }}
              // border: "1px dashed ",
              wordBreak: "normal",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: blogData?.content }} />
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: { xs: "flex", md: "initial" },
            justifyContent: { xs: "flex-end" },
          }}
        >
          <NavLink to={`/blog-details/${titleToUrl(blogData?.title)}`} style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                fontSize: { xs: "12px", md: "12px" },
                color: "white.main",
                textTransform: "uppercase",
                px: { xs: 2, md: 3 },
              }}
            >
              Read More
            </Button>
          </NavLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

LatestBlog.propTypes = {};

export default LatestBlog;
