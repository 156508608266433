import React, { useState } from "react";
import PropTypes from "prop-types";
// import Grid from "@mui/material/Unstable_Grid2";
import DefaultBlogImage from "../../assets/others/blog_image_default.jpeg";
import DefaultBlogImage1 from "../../assets/others/blog_image_default2.png";
import { Container, Box, Grid, Button, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { NavLink } from "react-router-dom";
import { titleToUrl } from "../../shared/extra";
const AllBlogs = ({ blogData }) => {
  const Mobile = useMediaQuery("(max-width:480px)");
  const [showMore, setShowMore] = useState(true);
  const [showLess, setShowLess] = useState(false);
  const [showBlogNo, setShowBlogNo] = useState(Mobile ? 3 : 6);
  const totalblog = blogData?.length;
  const handleShowMore = () => {
    setShowMore(true);
    setShowBlogNo(totalblog);
    setShowMore(false);
    setShowLess(true);
  };

  const handleShowLess = () => {
    setShowBlogNo(6);
    setShowMore(true);
    setShowLess(false);
  };

  return (
    <Container maxWidth="lg" sx={{ my: { xs: 2, md: 4 } }}>
      <Grid
        container
        spacing={2} // Adjust spacing between grid items
        sx={{ justifyContent: { xs: "center" } }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            my: { xs: 1, md: 1 },
          }}
        >
          <Typography
            variant="caption"
            align="center"
            sx={{
              color: "secondary.main",
              fontSize: { xs: "14px", sm: "14px", md: "14px" },
              fontWeight: { xs: 500, md: 500 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: { xs: 1, md: 1 },
            }}
          >
            <AutoAwesomeIcon sx={{ fontSize: { xs: "20px", md: "20px" } }} />{" "}
            Our Blogs
          </Typography>
          <Typography
            variant="h2"
            align="center"
            sx={{
              color: "primary.main",
              fontSize: { xs: "20px", md: "22px" },
            }}
          >
            Read our Latest Blogs
          </Typography>
        </Grid>

        {blogData?.map(
          (item, index) =>
            index < showBlogNo && (
              <Grid key={item.id} item xs={11} sm={6} md={4} lg={4} xl={4}>
                <ListAllBlogs blogData={item} />
              </Grid>
            )
        )}

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: { xs: 1, md: 3 },
            p: 0,
          }}
        >
          {showMore && (
            <Button variant="contained" onClick={handleShowMore}>
              <ArrowCircleDownIcon sx={{ mr: "3px" }} />
              Show More
            </Button>
          )}

          {showLess && (
            <Button variant="contained" onClick={handleShowLess}>
              <ArrowCircleUpIcon sx={{ mr: "3px" }} />
              Show Less
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

AllBlogs.propTypes = {};

export default AllBlogs;

function ListAllBlogs({ blogData }) {
  const blogImg = blogData.id % 2 === 0 ? DefaultBlogImage1 : DefaultBlogImage;
  return (
    <Grid
      container
      sx={{
        p: { xs: 1, md: 1 },
        backgroundColor: "#f1f5f6",
        borderRadius: { xs: 1, md: 3 },
        boxShadow: { xs: 1, md: 3 },
      }}
    >
      {/* Blog image */}
      <Grid
        item
        xs={12}
        sx={{
          height: { xs: "180px", md: "210px" },
          mb: { xs: 0, md: 0 },
          borderRadius: { xs: 2, md: 1 },
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          src={blogImg}
          alt="Blog Image"
          loading="lazy"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "fill",
          }}
        />
      </Grid>

      {/* Blog details */}
      <Grid
        item
        container
        xs={12}
        sx={{
          px: { xs: 1, md: 1 },
          my: { xs: 0, md: 1 },
        }}
      >
        <Grid item xs={12} sx={{ my: { xs: 2, md: 0 } }}>
          <Typography
            variant="caption"
            color="secondary.main"
            sx={{
              fontSize: { xs: "14px", md: "12px" },
              fontWeight: 500,
              textTransform: "uppercase",
            }}
          >
            Gift Voucher
          </Typography>

          <NavLink to={`/blog-details/${titleToUrl(blogData?.title)}`} style={{ textDecoration: "none" }}>
            <Typography
              variant="h1"
              color="primary.main"
              sx={{
                fontSize: { xs: "19px", md: "18px" },
                my: { xs: 1, md: 1 },
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 2,
                maxHeight: "3.6rem",
                lineHeight: { xs: "24px", md: "22px" },
                overflowWrap: "break-word",
                wordBreak: "normal",
                fontWeight: { xs: 400, md: 500 },
              }}
            >
              {blogData?.title ? blogData.title : null}
            </Typography>
          </NavLink>

          <Typography
            variant="caption"
            color="textPrimary.main1"
            sx={{
              fontSize: { xs: "12px", md: "12px" },
              fontWeight: 500,
              textTransform: "uppercase",
            }}
          >
            Post by 99Gift
          </Typography>

          <Typography
            variant="subtitle1"
            color="textPrimary.main"
            sx={{
              fontSize: { xs: "16px", md: "13px" },
              my: { xs: 1, md: 1 },
              lineHeight: { xs: "24px", md: "22px" },
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: { xs: 4, md: 5 },
              maxHeight: { xs: "95px", md: "120px" },
              overflowWrap: "break-word",
              wordBreak: "break-all",
              fontWeight: { xs: 300, md: 500 },
            }}
          >
            {/* {blogData?.content ? blogData.content : null} */}
            <div dangerouslySetInnerHTML={{ __html: blogData.content }} />
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <NavLink to={`/blog-details/${titleToUrl(blogData?.title)}`} style={{ textDecoration: "none" }}>
            <Button
              variant="outlined"
              sx={{
                fontSize: { xs: "12px", md: "12px" },
                color: "primary.main",
                textTransform: "uppercase",
                px: { xs: 2, md: 3 },
              }}
            >
              Read More
            </Button>
          </NavLink>
          <Typography
            variant="h1"
            color="textPrimary.main1"
            sx={{
              fontSize: { xs: "12px", md: "10px" },
              fontWeight: 500,
              textTransform: "uppercase",
              mx: { xs: 1, md: 1 },
            }}
          >
            March, 20 2024
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
