import React, { useEffect, useState } from 'react';
import { Box, Button } from "../../../../imports/muiImport";
import { NavLink, useDispatch, useNavigate, useSelector } from "../../../../imports/generalImport";
import { handleAmount } from '../../../../shared/extra';
import { productPurchaseApi } from '../customPaymentHook';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { fetchUserBalance } from '../../../../store/userBalance/action';
import { textTransform } from '@mui/system';

function UserPayButton({ isLoggedIn, ProductDiscount, walletPayment, purchaseLoading, setPurchaseLoading }) {
    const DENOMINATION_STORE_DATA = useSelector((state) => state.Denominations?.allData);
    const PRODUCT_DETAIL = useSelector((state) => state.ProductDetail?.allData);
    const USER_PROFILE = useSelector((state) => state.UserProfile?.allData);
    const USER_BALANCE = useSelector((state) => state.UserBalance?.allData);
    const [netPayOut, setNetPayout] = useState(0);
    const calculateNetPay = (items) => items.reduce((acc, item) => acc + item.TOTAL, 0);
    const TOTAL_DISCOUNT = (total, dis) => total / 100 * dis;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const redirectToHome = () => {
        navigate('/login', { replace: true });
    }

    useEffect(() => {
        if (DENOMINATION_STORE_DATA?.length > 0) {
            const total = calculateNetPay(DENOMINATION_STORE_DATA);
            const finalPay = TOTAL_DISCOUNT(total, ProductDiscount);
            if (walletPayment) {
                const netPayWallet = (total - finalPay) - USER_BALANCE;
                setNetPayout(netPayWallet >= 1 ? netPayWallet : 0);
            } else {
                const np = total - finalPay;
                setNetPayout(np);
            }
        } else {
            setNetPayout(0);
        }
    }, [DENOMINATION_STORE_DATA, ProductDiscount, walletPayment])

    return (
        <Box sx={{ marginTop: "10px", width: "100%" }}>

            {isLoggedIn ?
                (<Button
                    variant="contained"
                    fullWidth
                    disabled={!netPayOut && !walletPayment}
                    onClick={() => handleUserPayment(DENOMINATION_STORE_DATA, PRODUCT_DETAIL, USER_PROFILE, walletPayment, navigate, setPurchaseLoading, dispatch)}
                    sx={{
                        minHeight: {
                            xs: "55px",
                            lg: "60px"
                        },
                        textTransform: {
                            xs: "capitalize",
                            lg: "capitalize"
                        },
                        fontSize: {
                            xs: "1.2rem",
                            lg: "1.4rem"
                        },
                        borderRadius: { xs: 2, },
                        color: { xs: "white.main" },
                        "&: hover": {
                            boxShadow: 5,
                        }
                    }}
                >
                    <Stack alignItems="center" flexDirection="row" sx={{
                        justifyContent: "center"
                    }}>
                        {purchaseLoading ?
                            <Typography variant='button' sx={{ textTransform: "capitalize" }}>
                                Please Wait . . . . . . .
                            </Typography>
                            :
                            <Typography variant='subtitle1' sx={{ textTransform: "capitalize" }}>
                                {`Pay Only \u20B9 ${handleAmount(netPayOut)}`}
                            </Typography>
                        }
                    </Stack>
                </Button>)
                :
                (<Button
                    variant="contained"
                    fullWidth
                    onClick={redirectToHome}
                    sx={{
                        minHeight: {
                            xs: "55px",
                            lg: "60px"
                        },
                        textTransform: {
                            xs: "capitalize",
                            lg: "capitalize"
                        },
                        fontSize: {
                            xs: "1.2rem",
                            lg: "1.4rem"
                        },
                        borderRadius: { xs: 2, },
                        color: { xs: "white.main" },
                        "&: hover": {
                            boxShadow: 5,
                        }
                    }}
                >
                    {`Login & Pay \u20B9`}
                    {handleAmount(netPayOut)}
                </Button>)
            }
        </Box>
    )
}

const makePurchasePayloadDenomination = (denominations) => {
    let data = [];
    for (const iterator of denominations) {
        if (iterator.QUANTITY > 0) {
            const deno = {
                "amount": iterator.AMOUNT,
                "discount": iterator.DISCOUNT,
                "quantity": iterator.QUANTITY,
                "total": iterator.TOTAL,
                "PRODUCTCODE": iterator?.PRODUCT_CODE,
                "ProductGuid": iterator?.PRODUCT_GUID,
                "product_id": iterator?.PRODUCT_ID,
                "subproduct_id": iterator?.SUB_PRODUCT_ID,
                "skuID": iterator?.SKU_ID,
            }
            data.push(deno);
        }
    }
    return data;
}

const purchaseApi = async (purchasePayload, setPurchaseLoading, navigate, dispatch) => {
    const purchaseResponse = await productPurchaseApi(purchasePayload);
    dispatch(fetchUserBalance());
    if (purchaseResponse === "ORDER_PURCHASE_REDIRECT_TO_ORDERS") {
        navigate('/profile/order', { replace: true });
        setTimeout(() => {
            setPurchaseLoading(false);
        }, 300);
    }
}

function handleUserPayment(deno, product, user, wallet, navigate, setPurchaseLoading, dispatch) {
    setPurchaseLoading(true);
    const filteredDeno = makePurchasePayloadDenomination(deno);
    const productId = product?.['id'];

    const purchasePayload = {
        customerName: user?.data?.name,
        emailID: user?.data?.email,
        mobileNo: user?.data?.mobile,
        newSite: true,
        walletPayment: wallet,
        productId,
        device_type: 0,
        denominations: filteredDeno,
    }

    purchaseApi(purchasePayload, setPurchaseLoading, navigate, dispatch);
}
export default UserPayButton