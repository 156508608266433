import { apiFunction } from "../functions";

export const getAllCategories = async () => {
  return await apiFunction("categories", "get", {}, true, null);
};

// Login
export const getLogin = async (payload) => {
  return await apiFunction("user/login", "post", payload, false, null);
};

// Sign up
export const signUp = async (payload) => {
  return await apiFunction("user/register", "post", payload, false, null);
};

// Validate OTP -- otp send to user's phone
export const validateOtp = async (mobile) => {
  return await apiFunction(`user/reSendOtp/${mobile}`, "post", {}, false, null);
};

// Validate OTP -- user's otp validate --
export const verifyUserOtp = async (payload) => {
  return await apiFunction(`user/verifyOtp`, "post", payload, true, null);
};

// Validate OTP -- user's otp validate
export const verifyForgotUserOtp = async (payload) => {
  return await apiFunction(`user/verifyOtpForgot`, "post", payload, false, null);
};


// Get all Blogs
export const getBlogs = async () => {
  return await apiFunction("getblogs", "get", {}, false, null);
};

// HomePage

// Sliders Images / Hero Section Images
export const getSliderImages = async () => {
  return await apiFunction("sliders", "get", {}, false, null);
};

// All Categories
export const getAllCategory = async () => {
  return await apiFunction("categories", "get", {}, true, null);
};

// Top Five Products Api --
export const getTopFiveProducts = async () => {
  return await apiFunction("topfive", "get", {}, true, null);
};

// Best Seller Products Api --
export const getBestSellers = async () => {
  return await apiFunction("bestseller", "get", {}, true, null);
};

// Featured Products Api --
export const getFeaturedProducts = async () => {
  return await apiFunction("featured", "get", {}, true, null);
};

// All Product List
export const getAllProducts = async (payload) => {
  return await apiFunction("list", "post", payload, true, null);
};

// Product Details
export const getProductDetails = async (productId) => {
  return await apiFunction(`gift/details/${productId}`, "post", {}, true, null);
};


//Contact us
export const sendContactUs = async (payload) => {
  return await apiFunction("send-query", "post", payload, false, null);
};

//Contact us
export const sendCompany = async (payload) => {
  return await apiFunction("/submit-corporate", "post", payload, false, null);
};

//UserContact us 
export const userComplainQuery = async (payload) => {
  return await apiFunction("send-query", "post", payload, false, null);
};


//subscribe us
export const sendSubscribeUs = async (payload) => {
  return await apiFunction("subscribe", "post", payload, false, null);
};

//validate token
export const validateToken = async () => {
  return await apiFunction("user/validate-token", "post", {}, true, null);
};


//forgot password -- password change
export const forgotUserPassword = async (payload) => {
  return await apiFunction("user/forgot-password", "post", payload, true, null);
};


//Balance
export const userBalance = async () => {
  return await apiFunction("user/balance", "post", {}, true, null);
};

// Purchase Products
export const purchaseProduct = async (payload) => {
  return await apiFunction("user/gift/purchase", "put", payload, true, null);
};



// Dashboard Pages API CALLS
export const fetchUserOrders = async (payload) => {
  return await apiFunction(`user/reports/0`, 'post', payload, true, false)
}

export const fetchUserStatement = async (payload) => {
  return await apiFunction(`/user/account-statement`, 'post', payload, true, false)
}

// find Voucher Details
export const fetchVoucherDetail = async (orderId, payload) => {
  return await apiFunction(`/user/card-list-new/${orderId}`, 'post', payload, true, false)
}
