export function titleToUrl(title) {
  const url = title?.toLowerCase().replace(/\s+/g, "-");
  return url?.replace(/[^a-z0-9-]/g, "");
}

export function documentNameConverter(title) {
  // Convert title to uppercase
  const upperTitle = title?.toUpperCase();
  // Replace spaces with underscores
  const underscoredTitle = upperTitle?.replace(/\s+/g, "_");
  // Remove any characters that are not alphanumeric, underscores, or hyphens
  const cleanTitle = underscoredTitle?.replace(/[^A-Z0-9_-]/g, "");
  return cleanTitle;
}

// export function handleAmount(amount) {
//   return new Intl.NumberFormat("en-IN").format(amount);
// }

export function handleAmount(amount) {
  const isFloatingPoint = amount % 1 !== 0;
  return new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: isFloatingPoint ? 2 : 0,
    maximumFractionDigits: isFloatingPoint ? 2 : 0
  }).format(amount);
}


export function phoneNumberValidate(e) {
  const numericValue = e.target.value.replace(/\D/g, "");
  if (!/^\d+$/.test(numericValue)) {
    return null;
  }
  return numericValue;
}

export function validateEmail(email) {
  // Regular expression for a basic email validation
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  // Test the email against the regex
  return emailRegex.test(email);
}

export function validatePhone(phone) {
  // Regular expression for a basic phone number validation
  const phoneRegex = /^\+?[1-9][0-9]{7,14}$/;

  // Test the phone number against the regex
  return phoneRegex.test(phone);
}

export function getDateAndLastMonth() {
  const today = new Date();
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  return {
    today: today.toISOString().split("T")[0], // Format today's date as YYYY-MM-DD
    lastMonth: lastMonth.toISOString().split("T")[0], // Format last month's date as YYYY-MM-DD
  };
}



export function dateChanger(dateString) {
  const date = new Date(dateString);

  // Define an array of month names
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Format the date to "DD Month YYYY"
  const formattedDate = date.getDate().toString().padStart(2, '0') + ' ' +
    monthNames[date.getMonth()] + ' ' +
    date.getFullYear();
  return (formattedDate)
}

export function nameShortener(name, nameLength = 14) {
  if (name?.length > nameLength) {
    const trimName = name.slice(0, nameLength - 3);
    return `${trimName}...`;
  }
  return name;
}



export function shuffleArray(array) {
  let shuffledArray = array.slice(); // Create a copy of the array to avoid modifying the original array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Generate a random index
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Swap elements
  }
  return shuffledArray;
}


export function categoryUrl(title) {
  const url = title?.toLowerCase().replace(/\s+/g, "-");
  return url?.replace(/[^a-z0-9-]/g, "");
}